<template>
	<v-snackbar rounded="xl" :timeout="-1" v-model="cookies" app multi-line bottom color="info" left text :vertical="$vuetify.breakpoint.smAndDown">
		{{ $t('landing.cookiesMessage') }}
		<template #action>
			<v-btn text rounded plain color="secondary" :to="{ name: 'PoliciesCookies' }">
				{{ $t('landing.learnMore') }}
			</v-btn>
			<v-btn text rounded plain color="secondary" @click="cookies = !cookies">
				{{ $t('search.ok') }}
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: 'LandingCookies',
	data() {
		return {
			cookies: true,
		}
	},
}
</script>
